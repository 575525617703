// AuthProvider.tsx
import React from 'react';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import AuthContext from './AuthContext';
import { useSessionLogout } from '../../lib/useLogin';
import { stagingServerUrl } from '../../server/constants';
import { Origin, SnackBarSeverity } from '../../utils/enums';
import { privateApi, refreshAccessToken } from '../../lib/privateApi';
import { errorLog, noop } from '../../utils/commonUtils';
import { ADMIN_ROLES, AUTH_SESSION_KEY } from '../../utils/constants';
import { clear, remove } from '../../utils/storage';
import { SnackbarContext } from '../Snackbar/Context';

export const fetcher = async (url: string = `${stagingServerUrl}/sessions`, callBack: Function = noop) => {
  const params = {websiteOrigin: Origin.NIW_CORP};
  refreshAccessToken(callBack);
  return privateApi.get(url, {params}).then(res => res && res.data);
  // if (sessionUser && sessionUser.role === ROLES.Admin && !can(sessionUser, PermissionTypes.CMS_EDIT_ABILITY_NIW_CORP)) {
  //   const errMessage = 'ADMIN must have permission to edit CMS.';
  //   throw new Error(errMessage);
  // }
  //
  // return sessionUser;
};

export function AuthProvider({children}: { children: React.ReactNode; }) {
  const sessionGetUrl = `${stagingServerUrl}/sessions`;
  const snackbar = React.useContext(SnackbarContext);
  const {push, reload} = useRouter();

  const logout = async () => {
    try {
      await privateApi.delete(`${stagingServerUrl}/sessions`);

      let mu = null;

      if (ADMIN_ROLES.includes(user.role)) {
        const res = await useSessionLogout();
        mu = res.data;
      }

      await mutateUser(mu);
    } catch (e) {
      errorLog(e);
    } finally {
      refreshAccessToken((a: string) => snackbar.showMessage(a, SnackBarSeverity.Error));
      await remove(AUTH_SESSION_KEY);
      await clear();
      await push('/');
      reload();
    }
  };

  const cb = (errMsg: string) => {
    snackbar.showMessage(errMsg, SnackBarSeverity.Error);
    logout()
      .then();
  };
  const {data: user, mutate: mutateUser}: any = useSWR(sessionGetUrl, (url: string) => fetcher(url, cb));

  return (
    <AuthContext.Provider value={{user, mutateUser, logout}}>
      {children}
    </AuthContext.Provider>
  );
}
