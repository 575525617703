import React from 'react';

const AuthContext = React.createContext<{
    user: any | undefined;
    mutateUser: (response: any) => Promise<any>;
    logout: () => void;
}>({
    user: undefined,
    mutateUser: async () => null,
    logout: async () => null,
});

export default AuthContext;