import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { stagingServerUrl } from '../server/constants';
import { AUTH_SESSION_KEY, initFalse } from '../utils/constants';
import { consoleLog, errorLog } from '../utils/commonUtils';
import { get } from '../utils/storage';


export const privateApi = axios.create({
    baseURL: `${stagingServerUrl}/`,
});

export const refreshAccessToken = (cb?: Function) => {
  let isCalled = initFalse;
  privateApi.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const accessToken = await get(AUTH_SESSION_KEY);
    if (accessToken && config.headers) {
      config.headers.Authorization = "Bearer " + accessToken;
    }

    return config;
  });

  privateApi.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
      const _err = error && error.isAxiosError ? (error && error.response && error.response.data) : error;
      const errMessage = typeof _err === 'string' ? _err : _err.response || _err.message || 'Something went wrong try again in a moment';
      if (_err && (_err.statusCode || _err.status >= 400)) {
        if (isCalled) {
          return;
        }
        isCalled = !initFalse;
        errorLog(_err);
        consoleLog('API LOG:', errMessage);
        if (cb && typeof cb === 'function' && _err.status >= 401) {
          cb(errMessage);
        }
      }
      throw _err;
    }
  );
}
