import { createContext } from 'react';
import { Color } from '@material-ui/lab';
import { consoleLog } from '../../utils/commonUtils';

export const SnackbarContext = createContext({
  message: '',
  open: false,
  showMessage: (message: string, severity?: Color) => {
    consoleLog(message, severity);
  }
});
