import localForage from 'localforage';


export const get = async (key: string) => {
  return localForage.getItem(key);
}

export const remove = async (key: string) => {
  return localForage.removeItem(key);
}

export const save = async (key: string, value: string) => {
  return localForage.setItem(key, value);
}

export const clear = async () => {
  return localForage.clear();
}
