import { ROLES } from './enums';

export const MENUOPTIONS = {
  STRATEGYFORYOU: 1,
  FORAGENT: 2,
  STRATEGYMANAGEMENT: 3,
  ABOUT_NIW: 4,
  AGENT_LOCATOR: 5
};

export interface Video {
  title: string,
  videoUrl: string,
  designation: string,
  name: string
}

export const carouselVideos: Video[] = [
  {
    designation: 'Real State Agent',
    name: 'Carey Finn',
    title: 'Carey Finn Kai-Zen Testimonial - ILIA',
    videoUrl: 'https://player.vimeo.com/video/360842534'
  },
  {
    designation: 'Advisor',
    name: 'Pat Kunzn',
    title: 'Pat Kunz - Kai-Zen Testimonial',
    videoUrl: 'https://player.vimeo.com/video/360886953'
  },
  {
    designation: 'Advisor',
    name: 'David Bistritz',
    title: 'David Bistritz Kai-Zen Testimonial - ILIA',
    videoUrl: 'https://player.vimeo.com/video/362156467'
  },
  {
    designation: 'Real State Agent',
    name: 'Carey Finn',
    title: 'Saima Iqbal & Sal Mallick Kai-Zen Testimonial - ILIA',
    videoUrl: 'https://player.vimeo.com/video/364177068'
  },
  {
    designation: 'Advisor',
    name: 'Pat Kunzn',
    title: 'Aaron Zimmer Kai-Zen Testimonial - ILIA',
    videoUrl: 'https://player.vimeo.com/video/380362579'
  },
  {
    designation: 'Real State Agent',
    name: 'Carey Finn',
    title: 'Xuhong Hao Kai-Zen Testimonial - ILIA',
    videoUrl: 'https://player.vimeo.com/video/380363031'
  },
  {
    designation: 'Advisor',
    name: 'Pat Kunzn',
    title: 'Jay Jensen Kai-Zen Testimonial',
    videoUrl: 'https://player.vimeo.com/video/389348882'
  }
];

export const timeDropdown: object[] = [
  {
    key: '9AM - 11AM',
    value: '9AM - 11AM',
    name: '9AM - 11AM'
  }, {
    key: '10AM - 12PM',
    value: '10AM - 12PM',
    name: '10AM - 12PM'
  }, {
    key: '11AM - 1PM',
    value: '11AM - 1PM',
    name: '11AM - 1PM'
  }, {
    key: '12PM - 2PM',
    value: '12PM - 2PM',
    name: '12PM - 2PM'
  },
  {
    key: '1PM - 3PM',
    value: '1PM - 3PM',
    name: '1PM - 3PM'
  },
  {
    key: '2PM - 4PM',
    value: '2PM - 4PM',
    name: '2PM - 4PM'
  },
  {
    key: '3PM - 5PM',
    value: '3PM - 5PM',
    name: '3PM - 5PM'
  },
  {
    key: '4PM - 6PM',
    value: '4PM - 6PM',
    name: '4PM - 6PM'
  },
];

export const EDITOR_CONTROLS_IDS: string[] = [
  'mui-rte-h1id-button',
  'mui-rte-h2id-button',
  'mui-rte-h3id-button',
  'mui-rte-h4id-button',
  'mui-rte-h5id-button',
  'mui-rte-h6id-button',
  'mui-rte-body1id-button',
  'mui-rte-body2id-button',
  'mui-rte-captionid-button',
  'mui-rte-overlineid-button',
  'mui-rte-subtitle1id-button',
  'mui-rte-subtitle2id-button',
  'mui-rte-uploadimageid-button',
  'mui-rte-UL-button'
];

export const fiveYearTrustFee = 1350 * 5;

export const PWD_ONE_LETTER_REGEX = /^(?=.*[a-z])/;
export const PWD_ONE_CAPITAL_LETTER_REGEX = /^(?=.*[A-Z])/;
export const PWD_ONE_NUMBER_REGEX = /^(?=.*[0-9])/i;
export const PWD_MIN_EIGHT_CHAR_REGEX = /^(?=.{8,99})/i;
export const PWD_ONE_SPECIAL_CHAR = /^(?=.*[$*.{}?"!@#%&/,><\':;|_~`^\]\[\)\(])/;

export const ZIP_CODE_CHAR = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const genderDropDown: object[] = [
  {
    id: '',
    value: '',
    name: 'Select'
  }, {
    id: 'Male',
    value: 'Male',
    name: 'Male'
  }, {
    id: 'Female',
    value: 'Female',
    name: 'Female'
  }
];


export const deathBenefitDropDown: object[] = [
  {
    id: 1000000,
    value: 1000000,
    name: '1 MIL'
  },
  {
    id: 1500000,
    value: 1500000,
    name: '1.5 MIL'
  },
  {
    id: 2000000,
    value: 2000000,
    name: '2 MIL'
  },
];

export const segmentationDropDown = [
  {
    id: 1,
    value: 'administration',
    name: 'Administration'
  }, {
    id: 2,
    value: 'advertising',
    name: 'Advertising'
  }, {
    id: 3,
    value: 'agricultural',
    name: 'Agricultural'
  }, {
    id: 4,
    value: 'construction',
    name: 'Construction'
  }, {
    id: 5,
    value: 'consulting',
    name: 'Consulting'
  }, {
    id: 6,
    value: 'design&Architecture',
    name: 'Design & Architecture'
  }, {
    id: 7,
    value: 'education',
    name: 'Education'
  }, {
    id: 8,
    value: 'engineering',
    name: 'Engineering'
  }, {
    id: 9,
    value: 'financialServices',
    name: 'Financial Services'
  }, {
    id: 10,
    value: 'government&Defense',
    name: 'Government & Defense'
  }, {
    id: 11,
    value: 'hospitality&Tourism',
    name: 'Hospitality & Tourism'
  }, {
    id: 12,
    value: 'humanResources&Recruitment',
    name: 'Human Resources & Recruitment'
  }, {
    id: 13,
    value: 'information&Technology',
    name: 'Information & Technology'
  }, {
    id: 14,
    value: 'insurance',
    name: 'Insurance'
  }, {
    id: 15,
    value: 'legal',
    name: 'Legal'
  }, {
    id: 16,
    value: 'manufacturing',
    name: 'Manufacturing'
  }, {
    id: 17,
    value: 'marketing&Communications',
    name: 'Marketing & Communications'
  }, {
    id: 18,
    value: 'medical',
    name: 'Medical'
  }, {
    id: 19,
    value: 'realEstate',
    name: 'Real Estate'
  }, {
    id: 20,
    value: 'retail',
    name: 'Retail'
  }, {
    id: 21,
    value: 'sales',
    name: 'Sales'
  }, {
    id: 22,
    value: 'science&Technology',
    name: 'Science & Technology'
  }, {
    id: 23,
    value: 'sport&Recreation',
    name: 'Sport & Recreation'
  }, {
    id: 24,
    value: 'transport&Logistics',
    name: 'Transport & Logistics'
  }, {
    id: 25,
    value: 'other',
    name: 'Other'
  },
];

export const clientTypes = [
  {
    id: 0,
    value: 0,
    name: 'Individual'
  },
  {
    id: 1,
    value: 1,
    name: 'Multi-Life',
  },
  // {
  // 	id: 2,
  // 	value: 2,
  // 	name: "Association",
  // },
  {
    id: 3,
    value: 3,
    name: 'Group-Link',
  },
];

export const leadSourcesDropDown = [
  {
    key: 1,
    value: 1,
    name: 'Existing Client'
  },
  {
    key: 2,
    value: 2,
    name: 'Client referral'
  },
  {
    key: 3,
    value: 3,
    name: 'Bought list'
  },
  {
    key: 4,
    value: 4,
    name: 'Seminar event',
  },
  {
    key: 5,
    value: 5,
    name: 'Webinar event'
  },
  {
    key: 6,
    value: 6,
    name: 'IMO referral'
  },
  {
    key: 7,
    value: 7,
    name: 'NIW training'
  },
  {
    key: 8,
    value: 8,
    name: 'NIW webinar'
  },
  {
    key: 9,
    value: 9,
    name: 'Personal cold call'
  },
  {
    key: 10,
    value: 10,
    name: 'Conference'
  },
  {
    key: 11,
    value: 11,
    name: 'Social Media'
  },
  {
    key: 12,
    value: 12,
    name: 'Article/ white paper'
  },
  {
    key: 13,
    value: 13,
    name: 'Agent (CPA etc) referral'
  },
  {
    key: 14,
    value: 14,
    name: 'Advert'
  },
  {
    key: 15,
    value: 15,
    name: 'OTHER'
  }
]

export const unmaskCurrency = (num: string = '') => {
  return num.replace(/[^0-9.-]+/g, '');
}

export const initFalse = false;

export const AUTH_SESSION_KEY = 'ACCESS-KEY-AUTH';

export const ADMIN_ROLES = [ROLES[ 'Master Admin' ], ROLES.Admin];

export const prefixAgentNumber = 'agent_carrier_';

export const AGENT_SIGNING_KEY = 'AGENT_SIGNING_KEY';

export const AGENT_SELLER_SIGN_RECHECK_DURATION = 90; // in days

export const [lowerAgeLimit, upperAgeLimit] = [18, 65];
