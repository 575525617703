import axios from 'axios';
import { externalServerUrl, stagingServerUrl } from '../server/constants';
import { ROLES } from '../utils/enums';
import { va } from '../utils/commonUtils';

type LoginPayloadInterface = { email: string; password: string; role?: ROLES | ROLES[]; };



export const useLogin = async (loginPayloadInterface: LoginPayloadInterface) => {
  const {email, password, role} = loginPayloadInterface;
  const paramsConfig = {params: {jwt: 1}};
  const payload = {email, password};
  const url = `${stagingServerUrl}/sessions`;
  const res = await axios.post(url, payload, paramsConfig);
  const {accessToken, user} = res.data;
  if (va(role) ? !(role as ROLES[]).includes(user.role) : role && role !== user.role) {
    throw new Error(`Only ${va(role) ? 'ADMIN' : ROLES[role as ROLES]} can access this portal.`);
  }

  return {accessToken, user};
};

export const useSessionLogin = async ({email, firstName, id, lastName, role}: any) => {
  const signInUrl = `${externalServerUrl}/api/session`
  const payload = {email, firstName, id, lastName, role};

  return axios.post(signInUrl, payload);
};

export const useSessionLogout = async () => {
  const url = `${externalServerUrl}/api/session`;

  return axios.delete(url);
};

