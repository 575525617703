import React from 'react';
import Head from 'next/head';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from '../theme/NIWTheme';
import { AuthProvider } from "../components/Auth/AuthProvider";
import '../style/style.css';
import '../style/table.css';


export interface AppProps {
  Component: React.ClassicComponentClass | React.FunctionComponent,
  pageProps: any,
}

export default function App(props: AppProps) {
  const {Component, pageProps} = props;
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    // const jssStyles = document.querySelector('#jss-server-side');
    // if (jssStyles) {
    //   jssStyles.parentElement.removeChild(jssStyles);
    // }

    // @ts-ignore
    document.getElementById('__next').classList.add('layoutWrap')
  }, []);


  return (
    <React.Fragment>
      <Head>
        <title>NIW Companies</title>
        <link rel="shortcut icon" href="/favicon.ico"/>
        <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-60x60.png"/>
        <meta name="viewport"
              content="minimum-scale=1, initial-scale=1, width=device-width"/>
      </Head>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <AuthProvider>
          <Component {...pageProps} />
        </AuthProvider>
      </ThemeProvider>
    </React.Fragment>
  );
}


