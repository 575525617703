export const LOGO_BASE_URL = 'https://ilia-production.s3.amazonaws.com/';
const s3Url = "https://assets.niwcorp.com"; //process.env.NEXT_PUBLIC_S3_SERVER_URL;
export const serverUrl = "http://localhost:3000"; //process.env.NEXT_PUBLIC_SERVER_URL;
export const externalServerUrl = "https://www.niwcorp.com"; //process.env.NEXT_PUBLIC_EXTERNAL_SERVER_URL;
export const externalILIAServerUrl = "https://www.myilia.com"; //process.env.NEXT_PUBLIC_ILIA_SERVER_URL;
export const bucketURL = s3Url + "/CMSImages";
export const stagingServerUrl =  "https://api.myilia.com"; //process.env.NEXT_PUBLIC_API_SERVER_URL;
export const carouselDirectory = "CarouselImages";
export const carouselBucketURL = s3Url + "/CarouselImages";
export const videoBucketUrl = s3Url + "/videos";
export const carriersAssets = s3Url + "/carriers";

export const carrierId = 2; //process.env.NEXT_PUBLIC_CARRIER_ID;
export const carrierName = 'National Life - (LSW)';

export const contactUsEmail = "marketing@niwcorp.com";
export const bccEmail = "marketing@yourseoshop.com, swati.walia@simsaw.com";

export const showEditIconBlue = 'showEditIconBlue';
export const showEditIconWhite = 'showEditIcon';
export const showEditIconForBg = 'showEditIconForBg';

export const bing_search_content = "D03D74C40363FF6F1FB6CD382332D360"; //process.env.NEXT_PUBLIC_BING_SEARCH_CONTENT;
export const google_tag_manager = "GTM-MPFVZTG"; //process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER;
export const google_analytics = "UA-199844755-29"; //process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS;
export const facebook_pixel_id = "582076910520676";
