import { AxiosError } from 'axios';
import { NextRouter } from 'next/router';
import moment from 'moment';
import numeral from "numeral";
import { AGENT_SELLER_SIGN_RECHECK_DURATION, AGENT_SIGNING_KEY, AUTH_SESSION_KEY, initFalse } from './constants';
import { AgentType, Paths, ROLES } from './enums';
import { externalILIAServerUrl } from '../server/constants';
import { get, remove } from './storage';

export const unmaskPhoneNumber = (phone: string) => phone.replace(/\D+/g, '');

export const maskPhoneNumber = (phone: string) => {
    if (phone && phone.length === 10) {
        let usNumber: RegExpMatchArray | null | string = phone.match(/(\d{3})(\d{3})(\d{4})/);
        usNumber = usNumber && "(" + usNumber[1] + ") " + usNumber[2] + "-" + usNumber[3];
        return usNumber;
    }
    return phone;
};

export const sleep = (ms: number = 1000, cb: object = {}) => {
  return new Promise(
    resolve => setTimeout(() => {
      resolve(cb)
    }, ms)
  );
};

export const getRandomString = () => {
    const randomString = (new Date().getTime()).toString(36).toUpperCase();
    return Math.random().toString(36).slice(11).toUpperCase() + randomString.substring(2, 8);
};

export const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const regex_to_fetch_phone_from_string = /(?:[-+() ]*\d){10,13}/g;
export const defaultPhoneNumber = "800-294-9940";
export const defaultTollFreeNumber = "800-294-9940";

export const parseEmptyString = (str: string|null|undefined, replacer: string = '') => {
	return typeof str === 'string' ? str : replacer;
}

export const consoleLog = (log?: any, ...optionalParams: any[]) => {
  console.log(log, ...optionalParams);
}

export const errorLog = (log?: any, ...optionalParams: any[]) => {
  console.error(log, ...optionalParams);
}

export const handleFormError = (err: AxiosError) => {
  const _err = err && err.isAxiosError ? (err && err.response && err.response.data) : err;
  errorLog(_err);
  const errMessage = typeof _err === 'string' ? _err : _err.response || _err.message || 'Something went wrong try again in a moment';
  consoleLog(errMessage);
  return errMessage
}

export const noop = () => {};

export const pez = parseEmptyString;

export const stringify = (value: any, replacer: any = null, space: number | string = 2) => {
  return JSON.stringify(value, replacer, space);
};

type SA = {agentSigned: boolean; duration: number;}
export const checkSellerAgreementSignValid: (sa: SA[]) => boolean = (sa: SA[]) => {
  return !!(sa && sa.length && sa[0].agentSigned && sa[0].duration <= AGENT_SELLER_SIGN_RECHECK_DURATION);
};

export const redirectIfPreferred = (accessToken: string, user: any) => {
  if (user && user.agent && user.agent.agentType === AgentType.Approved) {
    return initFalse;
  }

  window.location.href = `${externalILIAServerUrl}/agent/preferred/${accessToken}`;
  return !initFalse;
};


const checkValidArray = (arr: any) => {
  return Array.isArray(arr) && arr.length;
};

/**
 * Check If Valid Non-Empty Array
 */
export const va = checkValidArray;

export const formatDate = (arg: string) => moment(arg).format('MM-DD-YYYY');

export const checkErrorStatusCode = (err: any) => {
  return (err.response && (err.response.status === 403 || err.response.status === 401)) || (err.statusCode && (err.statusCode === 403 || err.statusCode === 401));
}

/**
 * Verify if session auth user and token are valid Else clear session
 * @param r
 * @param authUser
 */
export const verifyLastSession = async (r: NextRouter, authUser: any) => {
  const _authToken = await get(AUTH_SESSION_KEY);
  if (_authToken && !authUser) {
    remove(AUTH_SESSION_KEY)
      .then(() => sleep());
      // .then(() => r.reload());
  } else if (_authToken && authUser) {
    const agent_agreement_signing = await get(AGENT_SIGNING_KEY);
    if (!agent_agreement_signing) {
      return r.push(Paths.AgentPortal);
    }
  }
}

/**
 * Join firstName, lastName of user and returns full_name
 * @param user
 */
export const joinAsFullName = (user: {firstName: string, lastName: string}) => {
  return user && (pez(user.firstName) + ' ' + pez(user.lastName));
};

/**
 * has permission
 * @param user
 * @param permissionTypeId
 */
export const can = (user: {role: number, permissions: any[]}, permissionTypeId: number) => {
  if (!user) {
    return;
  }

  if (user.role === ROLES['Master Admin']) {
    return !initFalse;
  }

  const permissions = user.permissions || [];
  const permission = permissions.find(x => x.permissionType.id === permissionTypeId);

  return user.role === ROLES.Admin && permission && permission.isEnabled;
};


type NAT = (end: number, start?: number, increment?: number) => number[];
/**
 * Generate Numeric Array with default starting at zero
 * @param end
 * @param start
 * @param increment
 */
export const numArray: NAT = (end, start = 0, increment = 1) => {
  const rows: number[] = [];
  for (let i = start; i <= end; i += increment) {
    rows.push(i);
  }
  return rows;
};

/**
 * masking number as Currency
 * @param num
 */
const maskCurrency = (num: string = "") => {
  return numeral(num).format('$0,0');
};

export const mc = maskCurrency;
