export enum ROLES {
  'Master Admin' = 1,
  Admin,
  IMO,
  Agent,
  Individual,
  MultiLife,
  Carrier,
  Group,
  Association,
  GroupLink,
  'GroupLink User',
  Trustee,
  Bank,
  'IMO Member' = 15,
  'Group Signer'
}

export enum Origin {
  NIW_ADMIN = 'niwweb',
  NIW_CORP = 'niwcorp'
}

export enum SnackBarSeverity {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

export enum Paths {
  Home = '/',
  AgentPortal = '/portal',
  AgentPortalActionItems = '/portal/action-items',
  AgentPortalEnrollmentAllianz = '/portal/enrollment-forms/allianz',
  AgentPortalEnrollmentDir = '/portal/enrollment-forms',
  AgentPortalEnrollmentSample = '/portal/enrollment-forms/sample',
  AgentPortalProfile = '/portal/profile',
  AgentPortalTraining = '/portal/agent-training',
  AgentPortalTrancheDates = '/portal/tranche-dates',
  AgentResources = '/agent-resources',
  Allianz = '/allianz',
  Annexus = '/annexus',
  BusinessPlanning = '/business-planning',
  CarrierDocumentsDir = '/carrier-documents',
  EstatePlanning = '/estate-planning',
  EstatePlanningLS = '/estate-planning/life-strategies',
  EstatePlanningMG = '/estate-planning/multi-gen',
  EstatePlanningPFR = '/estate-planning/premium-finance-rescue',
  EstatePlanningTR = '/estate-planning/trust-revitalization',
  MinnesotaLife = '/minnesota-life',
  NationalLife = '/national-life',
  RetirementPlanning = '/retirement-planning',
  RetirementPlanningESD = '/retirement-planning/enhanced-split-dollar',
  RetirementPlanningKZ = '/retirement-planning/kai-zen',
}

export enum IrrStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export enum LoginType {
  Agent = 'Agent',
  Client = 'Client'
}

export enum RouterEvents {
  RouteChangeStart = 'routeChangeStart',
  RouteChangeComplete = 'routeChangeComplete',
  RouteChangeError = 'routeChangeError'
}

export enum JsEvents {
  Load = 'load',
  Scroll = 'scroll',
}

export enum AgentType {
  Approved,
  Preferred
}

export enum PermissionTypes {
  ACCOUNT_MANAGEMENT = 1,
  NIW_ACTION_ITEMS = 2,
  CASE_DETAILS = 3,
  TRANCHE_MANAGEMENT = 4,
  ESTIMATOR_MANAGEMENT = 5,
  STRATEGY_MANAGEMENT = 6,
  ADMIN_ACTIVITY_LOG = 7,
  LEAD_MANAGEMENT = 8,
  LINK_REPORTING = 9,
  CARRIER_REQUESTS = 10,
  TICKETS = 11,
  POWER_BI_REPORTS = 12,
  CMS_EDIT_ABILITY_NIW_CORP = 13,
  ILIA_TRAINING_CMS = 14
}
